export function status(coupon) {
  return coupon.cart_available === false
    ? '不適用'
    : coupon.status === 'voided'
    ? '已取消'
    : coupon.status === 'used' || coupon.used
    ? '已兌換'
    : coupon.expired_time && coupon.expired_time < new Date().getTime()
    ? '已逾期'
    : coupon.status === 'available'
    ? '可使用'
    : '可使用';
}

export function getId(coupon) {
  const id = coupon._id?.$oid || coupon.id || '';
  return id.slice(-8);
}
