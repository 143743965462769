import React from 'react';
import styled from 'styled-components';
import {ChevronLeft} from '@styled-icons/feather/ChevronLeft';
import {navigate} from 'gatsby';
import * as AppActions from '../../AppActions';
import AdminForm from 'rev.sdk.js/Generators/AdminResource/AdminForm';
import qs from 'query-string';
import LayaOrderExtra from '../../Generators/AdminResource/LayaOrderExtra';
import AdminDateTimeWidget from '../../Generators/AdminResource/AdminDateTimeWidget';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Ant from 'antd';

const path = '/admin/store_orders';
const collection = 'order';

export default function AdminOrderDetail(props) {
  const {id} = qs.parse(props.location.search);
  const [record, setRecord] = React.useState();
  const [profile, setProfile] = React.useState();

  const getRecord = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let record = await JStorage.fetchOneDocument(collection, {id});
      setRecord(record);
    } catch (err) {
      Ant.message.error('發生錯誤');
    }
    AppActions.setLoading(false);
  }, [id]);

  React.useEffect(() => {
    getRecord();
  }, [getRecord]);

  React.useEffect(() => {
    if (record?.owner && record?.origin !== 'pos') {
      (async () => {
        try {
          let resp = await JStorage.fetchOneDocument('user_profile', {
            owner: record.owner,
          });
          setProfile(resp);
        } catch (e) {}
      })();
    }
  }, [record?.owner]);

  return (
    <Wrapper>
      <Row>
        <BackButton
          className="resource-back-button"
          onClick={() => {
            if (typeof window !== 'undefined' && window.history.length > 1) {
              navigate(-1);
            } else {
              navigate(path);
            }
          }}>
          <ChevronLeft size={24} />
          <div className="back-btn-text">{'返回'}</div>
        </BackButton>
        <h1 style={{marginLeft: 10}}>訂單詳情</h1>
      </Row>

      {record && (
        <React.Fragment>
          <AdminForm
            instance={record}
            renderCustomSection={null}
            modifyJStorageArgs={{}}
            primaryKey={'id'}
            collection={'order'}
            actionBar={[]}
            formSpec={{
              schema: {
                title: '',
                type: 'object',
                properties: {
                  order_id: {
                    type: 'string',
                    title: '訂單ID',
                    readOnly: true,
                  },
                  order_number: {
                    type: 'string',
                    title: '訂單編號',
                    readOnly: true,
                  },
                  total: {
                    type: 'number',
                    title: '總價',
                    readOnly: true,
                  },
                  status: {
                    type: 'string',
                    title: '訂單狀態',
                    readOnly: true,
                  },
                  payment_status: {
                    type: 'string',
                    title: '付款狀態',
                    readOnly: true,
                  },
                  pick_time: {
                    type: 'string',
                    title: '取餐時間',
                    readOnly: true,
                  },
                },
              },
            }}
            config={{}}
            path={path}
            rjsfProps={{
              widgets: {
                'admin-date-time-widget': AdminDateTimeWidget,
              },
              fields: {},
            }}
          />
          <LayaOrderExtra
            context={{instance: record}}
            path={path}
            profile={profile}
          />
        </React.Fragment>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    margin: 10px 0;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  border-bottom: 1px solid transparent;
  padding: 5px 10px 5px 0px;
  background-color: transparent;
  cursor: pointer;

  & > .back-btn-text {
    font-size: 1.2rem;
  }

  &:hover {
    border-bottom: 1px solid black;
  }
`;
